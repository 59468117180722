import { createSlice } from '@reduxjs/toolkit';
import { getMaterialById, getMateriaux } from './materiauxThunk';
const initialState = {
    data: [],
    oneData: undefined,
    loading: false,
    error: null,
    total: 0,
};
const slice = createSlice({
    name: 'materiaux',
    initialState,
    reducers: {
        clearOneData: (state) => {
            state.oneData = undefined;
        },
        clearMateriaux: (state) => {
            state.data = [];
        },
        loadMateriaux: (state) => {
            state.loading = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMateriaux.pending, (state, actions) => {
            state.loading = true;
        })
            .addCase(getMateriaux.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.data = payload.docs;
            state.total = payload.meta.totalDocs;
        })
            .addCase(getMateriaux.rejected, (state, { payload }) => {
            state.error = true;
            state.loading = false;
        })
            .addCase(getMaterialById.pending, (state, actions) => {
            state.loading = true;
        })
            .addCase(getMaterialById.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.oneData = payload?.data?.data;
        })
            .addCase(getMaterialById.rejected, (state, { payload }) => {
            state.error = true;
            state.loading = false;
        });
    },
});
// Reducer
export default slice.reducer;
// Actions
export const { clearOneData, clearMateriaux, loadMateriaux } = slice.actions;
